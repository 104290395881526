<template>
  <div>
    <!-- 联系我们banner -->
    <page-banner :info="bannerInfo"></page-banner>
    <!-- 联系我们 -->
    <div class="page-section">
      <div class="container">
        <div class="content">
          <h3 class="page-title">联系我们</h3>
          <a-row type="flex" :gutter="[20, 20]">
            <a-col v-for="(item, i) in infoList" :key="i" :span="12">
              <div class="service-card">
                <div class="css-sprite"><i :class="`icon-${i}`"></i></div>
                <h6>{{ item.label }}</h6>
                <p>{{ item.value }}</p>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <!-- 企业优势  -->
    <company-advantage></company-advantage>
    <!-- 企业地址 -->
    <div class="page-section">
      <div class="container map-padding">
        <div class="map-img"></div>
      </div>
    </div>
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner.vue";
import CompanyAdvantage from "@/components/CompanyAdvantage.vue";
/****************
 *@author: niubj
 *@date: 2021-11-24 14:03:18
 *@version: V1.0.0
 *@description: 联系我们
 *************************************************************************/
export default {
  name: "",
  components: { PageBanner, CompanyAdvantage },
  props: {},
  data() {
    return {
      bannerInfo: {
        title: "Contact us",
        subtitle: "联系我们",
        bgClass: "contact-bg",
      },
      infoList: [
        {
          label: "电话",
          value: "010-80429920",
        },
        {
          label: "地址：",
          value: "北京市顺义区竺园二街2号院8号楼",
        },
      ],
    };
  },

  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped>
.service-card {
  padding: 1rem 2rem;
  text-align: center;
  .css-sprite {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    background-color: #1890ff;
    border-radius: 50px;
    color: #fff;
    i{
      display: inline-block;
      width: 25px;
      height: 25px;
      margin: auto;
      background: url("../assets/images/css_sprites_icon.png") no-repeat;
      &.icon-0{
        background-position: -8px -8px;
      }
      &.icon-1{
        background-position: -49px -8px;
      }
    }
  }
  h6 {
    line-height: 2;
    font-size: 20px;
    color: #333;
  }
  p {
    font-size: 16px;
    min-height: 64px;
  }
}
.map-padding {
  padding: 2rem 0;
  width: 1344px;
  .map-img {
    background: url("../assets/images/map.jpg") no-repeat center/contain;
    width: 100%;
    height: 520px;
  }
}
</style>
